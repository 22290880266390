import imagesLoaded from "imagesloaded";
import {throttle} from "throttle-debounce";

export default {
  data() {
    return {
      locoScroll: null,
    };
  },
  computed: {
    showNav() {
      return this.$store.state.showNav
    },
    scrollToOffset() {
      return this.$store.state.scrollToOffset
    },
  },
  watch: {
    // Add this watch
    '$store.state.scrollToTarget'(newTarget) {
      if (newTarget && this.locoScroll) {
        this.locoScroll.scrollTo(newTarget, {
          offset: -this.scrollToOffset, // You can customize the offset if needed
          duration: 1500, // Duration of the scroll animation
          easing: [0.25, 0.00, 0.35, 1.00], // Easing function for the scroll animation
          disableLerp: false, // Set to true if you want to disable smooth scrolling for this specific scroll
          scrollFromAnywhere: true
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initLocomotiveScroll();

      setTimeout(() => {
        if (this.locoScroll) {
          this.locoScroll.update()
        }
      }, 3000);
    });
  },
  beforeDestroy() {
    this.destroyLocomotiveScroll();
  },
  methods: {
    initLocomotiveScroll() {
      const scrollContainer = this.$el.querySelector('[data-scroll-container]');
      if (!scrollContainer) {
        console.warn('Scroll container not found');
        return;
      }

      this.locoScroll = this.$initLocomotiveScroll(scrollContainer);

      // Update ScrollTrigger after Locomotive Scroll updates
      this.locoScroll.on('scroll', this.$ScrollTrigger.update);

      const throttledScrollHandler = throttle(300, this.handleScroll);

      this.locoScroll.on('scroll', (context) => {
        if (!this.disableToggleNav) {
          throttledScrollHandler(context);
        }
      });


      // Refresh ScrollTrigger after Locomotive Scroll initialization
      const vm = this;

      this.$ScrollTrigger.scrollerProxy('[data-scroll-container]', {
        scrollTop(value) {
          if (!vm.locoScroll) return 0;

          return arguments.length ? vm.locoScroll.scrollTo(value, {
            duration: 0,
            disableLerp: true
          }) : vm.locoScroll.scroll.instance.scroll.y;
        }, // we don't have to define a scrollLeft because we're only scrolling vertically.
        getBoundingClientRect() {
          return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
        },
        // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
        pinType: document.querySelector('[data-scroll-container]').style.transform ? "transform" : "fixed"
      });
      // Define refreshHandler
      this.refreshHandler = () => this.locoScroll.update();

      // fix locomotive bug on setting up too early (img not yet loaded)
      imagesLoaded(scrollContainer, {background: true}, () => {
        this.locoScroll.update();
      });

      this.$ScrollTrigger.addEventListener('refresh', () => this.refreshHandler);
      this.$ScrollTrigger.refresh();
      this.$store.commit('setLocomotiveScrollInitialized', true);
    },
    destroyLocomotiveScroll() {
      // Remove the 'refresh' event listener before destroying the Locomotive Scroll instance
      this.$ScrollTrigger.removeEventListener('refresh', this.refreshHandler);

      // Clean up before destroying the component
      this.$ScrollTrigger.getAll().forEach(st => st.kill());
      this.locoScroll.off('scroll', this.$ScrollTrigger.update);
      this.locoScroll.destroy();
      this.locoScroll = null;
      this.$store.commit('setLocomotiveScrollInitialized', false);
    },
    resetLocoScroll() {
      if (this.locoScroll) {
        this.destroyLocomotiveScroll();
        this.initLocomotiveScroll();
      }
    },
    handleScroll(instance) {
      if (instance.direction === "down" && this.showNav) {
        this.$store.commit('setShowNav', false);
      } else if (instance.direction === "up" && instance.scroll.y < 100) {
        this.$store.commit('setShowNav', true);
      }
    }
  },
};
